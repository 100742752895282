import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    this.options = this._generateOptions()

    this.container = document.createElement('div')
    this.button = document.createElement('button')
    this.dropdown = document.createElement('div')
    this.inputFilter = document.createElement('input')
    this.list = document.createElement('ul')

    this._initContainer()
    this._initButton()
    this._initDropdown()
    this._initBinds()
    this._setFocus(this.element.selectedIndex)
    this._selectOption(this.options[this.element.selectedIndex])
  }

  _generateOptions() {
    return [...this.element.options].map((option, index) => {
      const li = document.createElement('li')
      li.classList.add('super-select-option')
      li.innerHTML = option.text
      li.setAttribute('data-index', index)
      li.addEventListener('click', this._onOptionClicked.bind(this))

      return li
    })
  }

  hide(event) {
    if (this.container.contains(event.target) === false && !this.dropdown.classList.contains('hidden')) {
      this.dropdown.classList.add('hidden')
    }
  }

  _initContainer() {
    this.container.classList.add('super-select')
    this.element.insertAdjacentElement('afterend', this.container)
    this.element.style.display = 'none'
  }

  _initButton() {
    this.button.classList.add('super-select-button', 'form-select')
    this.button.setAttribute('type', 'button')
    this.button.innerHTML = 'Selecione'
    this.container.append(this.button)
  }

  _initDropdown() {
    this._filteredOptions().forEach(o => this.list.append(o))
    this.list.classList.add('super-select-options')
    this.inputFilter.classList.add('super-select-input')
    this.inputFilter.setAttribute('placeholder', 'Filtrar...')
    this.dropdown.append(this.inputFilter)
    this.dropdown.append(this.list)
    this.dropdown.classList.add('super-select-dropdown', 'hidden')
    this.container.append(this.dropdown)
  }

  _setFocus(index = 0) {
    this.focusIndex = index

    if (this.focusIndex < 0) {
      this.focusIndex = this._filteredOptions().length - 1
    }

    if (this.focusIndex > this._filteredOptions().length - 1) {
      this.focusIndex = 0
    }

    if (this._filteredOptions().length === 0) return

    this.options.forEach(option => option.classList.remove('is-active'))
    this._filteredOptions()[this.focusIndex].classList.add('is-active')

    this._filteredOptions()[this.focusIndex].scrollIntoView({
      block: 'nearest'
    })
  }

  _refreshList() {
    this.list.innerHTML = ''
    this._filteredOptions().forEach(o => this.list.append(o))
  }

  _initBinds() {
    this.button.addEventListener('click', this._onButtonClicked.bind(this))
    this.inputFilter.addEventListener('keydown', this._onInputFilterKeyDown.bind(this))
    this.inputFilter.addEventListener('keyup', this._onInputFilterKeyDown.bind(this))
  }

  _onInputFilterKeyDown(e) {
    if (e.type === 'keyup') {
      this._refreshList()
      return
    }

    if (e.keyCode === 38) {
      this._setFocus(this.focusIndex - 1)
      e.preventDefault()
    } else if (e.keyCode === 27) {
      this._toggleDropdown()
    } else if (e.keyCode === 40) {
      this._setFocus(this.focusIndex + 1)
      e.preventDefault()
    } else if (e.keyCode === 13) {
      this._selectOption(this._filteredOptions()[this.focusIndex])
      this._toggleDropdown()
      this.button.focus()
      e.preventDefault()
    }
  }

  _onOptionClicked(e) {
    e.preventDefault()
    this._selectOption(e.target)
    this._toggleDropdown()
  }

  _selectOption(option) {
    this.button.innerHTML = option.innerHTML
    this.element.selectedIndex = option.dataset.index
  }

  _toggleDropdown() {
    this.dropdown.classList.toggle('hidden')
  }

  _filteredOptions() {
    const query = this.inputFilter.value.toLowerCase()
    return this.options.filter(option => option.innerHTML.toLowerCase().indexOf(query) !== -1)
  }

  _onButtonClicked(e) {
    this._toggleDropdown()
    this.inputFilter.focus()
    e.preventDefault()
  }
}
