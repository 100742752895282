/* eslint no-console:0 */

import Mustache from 'mustache';
import LazyLoad from "vanilla-lazyload";
import Cookie from 'js-cookie';

function headers(options) {
  options = options || {}
  options.headers = options.headers || {}
  options.headers['X-Requested-With'] = 'XMLHttpRequest'
  return options
}

window.update_current_user = () => {
  if (Cookie.get("logged_in") == null) {
    return null
  }

  fetch('/users/me', headers)
  .then(function(response) {
    if (response.status === 200) {
      return response
    }
  })
  .then(function(response) {
    if (response) {
      response.json().then(function(user) {
        Array.from(document.getElementsByClassName('template-authenticity_token')).forEach(function (template) {
          template.parentElement.insertAdjacentHTML('beforeend', Mustache.render(template.innerHTML, user))
        })

        if (user.signed_in) {
          Array.from(document.getElementsByClassName('template-signed-user')).forEach(function (template) {
            template.parentElement.innerHTML = Mustache.render(template.innerHTML, user)
          })

          Array.from(document.getElementsByClassName('template-owner-user')).forEach(function (template) {
            if (template.getAttribute('data-user-hash') == user.hash) {
              template.parentElement.innerHTML = Mustache.render(template.innerHTML, user)
            }
          })

          Array.from(document.getElementsByClassName('template-provider-user')).forEach(function (template) {
            if ('provider' == user.role || user.role == 'admin') {
              template.parentElement.innerHTML = Mustache.render(template.innerHTML, user)
            }
          })
        }
        var myLazyLoad = new LazyLoad();
        myLazyLoad.update();
      });
    }
  });
}

window.update_current_user()

function LoginModal() {
  document.getElementsByClassName('login_modal')[0].click();
}

Array.from(document.getElementsByClassName('no-user-input')).forEach(function (e) {
  e.addEventListener("click", LoginModal);
});
