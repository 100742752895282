import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {}
  static targets = [ "source", "filterable" ]

  filter(event) {
    let lowerCaseFilterTerm = this.sourceTarget.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")

    this.filterableTargets.forEach((el, i) => {
      let filterableKey =  el.getAttribute("data-filter-key")
      el.classList.toggle("hidden", !filterableKey.includes( lowerCaseFilterTerm ) )
    })
  }
}
