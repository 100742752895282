window.loadGoogleMaps = () => {
  var map = new google.maps.Map(document.getElementById("map"), window.maps.init);

  window.maps.markers.forEach(function(obj, index) {
    var markerOption = {position: obj["position"], map: map};
    if (obj["draggable"]) {
      markerOption["draggable"] = true
    }

    if (obj["icon"]) {
      markerOption["icon"] = obj["icon"]
    }

    var marker = new google.maps.Marker(markerOption);

    if (obj["update_school_form"]) {
      marker.addListener('dragend', function(event) {
        document.getElementById("school_update_latitude").value = event.latLng.lat();
        document.getElementById("school_update_longitude").value = event.latLng.lng();
        return true
      });
    }
  });
};
