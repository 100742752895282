/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import './src/application-base.css';
import '@github/details-dialog-element';
import { tns } from 'tiny-slider/src/tiny-slider';
import LazyLoad from "vanilla-lazyload";
import '@github/include-fragment-element'
import Rails from 'rails-ujs';
import './src/current_user_loader';
import './src/maps';
import { format, render, cancel, register } from 'timeago.js/dist/timeago.full.min.js';
import Mustache from 'mustache';
import ahoy from "./src/ahoy.js";
import { Application } from "stimulus"
import Remote from "stimulus-remote-rails"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import IMask from 'imask';

window.replace_html_reload_script = (dom, html) => {
  dom.innerHTML = html;
  var scripts = dom.getElementsByTagName("script");
  for (var i = 0; i < scripts.length; i++) {
      eval(scripts[i].innerText);
  }
};

window.load_mask = ()=> {
  var elements = document.querySelectorAll('.phone-mask');
  var maskOptions =  {
    mask: [
      {
        mask: '(00) 00000-0000',
        lazy: false
      },
      {
        mask: '(00) 0000-0000',
        lazy: false
      }
    ],
    dispatch: function (appended, dynamicMasked) {
      var number = (dynamicMasked.value + appended).replace(/\D/g,'');
      return number.length > 10 ? dynamicMasked.compiledMasks[0] : dynamicMasked.compiledMasks[1];
    }
  };

  elements.forEach(element =>  IMask(element, maskOptions));

  var elements = document.querySelectorAll('.zipcode-mask');
  var maskOptions =  {
    mask: "00000-000"
  };

  elements.forEach(element =>  IMask(element, maskOptions));
};

window.load_mask();

const importer = {
  url: (url) => {
    return new Promise((resolve, reject) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.addEventListener('load', () => resolve(script), false);
      script.addEventListener('error', () => reject(script), false);
      document.body.appendChild(script);
    });
  },
  urls: (urls) => {
    return Promise.all(urls.map(importer.url));
  }
};

window.lazyLoadInstance = new LazyLoad({
  elements_selector: `.lazy`,
});

window.timeago_render = () => {
  if (document.querySelectorAll('.time_tag').length == 0) return;
  render(document.querySelectorAll('.time_tag'), 'pt-BR')
};

window.close_modal = ()=> {
  Array.from(document.getElementsByClassName('modal-dialog')).forEach(function (element) {
    element.parentElement.toggle(false)
  })
}

window.notify = (text, type)=> { Array.from(document.getElementsByClassName('template-alert-'+type)).forEach(function (template) {
                                                  template.parentElement.insertAdjacentHTML('beforebegin', Mustache.render(template.innerHTML, { 'alert': text }));
                                              })

  var alert_boxes = document.querySelectorAll('.alert-'+ type + ' .cursor-pointer');

  if (alert_boxes) {
    Array.from(alert_boxes).forEach((alert_box) =>
      alert_box.addEventListener("click", function(){
        document.querySelectorAll('.alert-'+ type)[0].remove();
      })
    )}
}

window.load_notify = () => {
    fetch('/users/notify').then(function(response) {
      response.json().then(function(response) {
        Array.from(response).forEach(function (notify) {
          window.notify(notify[1], notify[0] == "notice" ? "success" : "danger");
        });
      })
    });

}

window.load_notify();

if (window.location.hash == '#to-check') {
  window.location.hash = '';
  window.notify("Registro inserido para nossa equipe verificar!", "success");
}

if (window.location.hash == '#changed') {
  window.location.hash = '';
  window.notify("Registro alterado! Pode demorar alguns minutos para atualizar!", "success");
}


const toggleVisibility = (el) => {
  el.classList.toggle("collapse");
  el.classList.toggle("fade");
  el.classList.toggle("in");
};

const openMenu = function ({ currentTarget: button }) {
  const menuId = button.dataset.collapseTargetId;
  const menuEl = document.querySelector(`#${menuId}`);

  const icons = button.querySelectorAll("svg");

  if (button.dataset.label == "Menu") {
    button.setAttribute("aria-label", "Fechar");
    button.setAttribute("aria-expanded", "true");

  }
  else {
    button.setAttribute("aria-label", "Menu");
    button.removeAttribute("aria-expanded");

  }

  button.dataset.label = button.dataset.label == "Menu" ? "Fechar" : "Menu";
  [menuEl, ...icons].forEach((el) => toggleVisibility(el));
};

const localeFunc = (number, index, total_sec) => {
  return [
    ['agora mesmo', 'daqui um pouco'],
    ['há %s segundos', 'em %s segundos'],
    ['há um minuto', 'em um minuto'],
    ['há %s minutos', 'em %s minutos'],
    ['há uma hora', 'em uma hora'],
    ['há %s horas', 'em %s horas'],
    ['há um dia', 'em um dia'],
    ['há %s dias', 'em %s dias'],
    ['há uma semana', 'em uma semana'],
    ['há %s semanas', 'em %s semanas'],
    ['há um mês', 'em um mês'],
    ['há %s meses', 'em %s meses'],
    ['há um ano', 'em um ano'],
    ['há %s anos', 'em %s anos']
  ][index];
};

document.addEventListener('DOMContentLoaded', function() {
  Rails.start();

  const application = Application.start()
  const context = require.context("./src/controllers", true, /\.js$/)
  application.load(definitionsFromContext(context))
  application.register("remote", Remote)

  const sliders = document.querySelectorAll('[data-slider]');

  sliders.forEach(el => {
    tns({
      loop: false,
      mouseDrag: true,
      container: el,
      swipeAngle: false,
      speed: 400,
      items: 1,
      navPosition: 'bottom',
      prevButton: '[data-slider-btn="prev"]',
      nextButton: '[data-slider-btn="next"]',
      responsive: {
        992: {
          items: 2
        },
        1200: {
          items: 3
        }
      }
    });
  });

  const tutorial = document.querySelectorAll('[data-tutorial-slider]');

  tutorial.forEach(el => {
    tns({
      loop: false,
      mouseDrag: true,
      container: el,
      swipeAngle: false,
      speed: 400,
      items: 1,
      navPosition: 'bottom',
      prevButton: '[data-slider-btn="prev"]',
      nextButton: '[data-slider-btn="next"]'
    });

  });

  var img_slider = document.querySelectorAll('[data-image-slider]');

  img_slider.forEach(el => {
    tns({
      loop: false,
      mouseDrag: true,
      container: el,
      swipeAngle: false,
      speed: 400,
      items: 1,
      navPosition: 'bottom',
      prevButton: '[data-image-slider-btn="prev"]',
      nextButton: '[data-image-slider-btn="next"]',
      responsive: {
        992: {
          items: 2
        },
        1200: {
          items: 3
        }
      }
    });
  })


  var img_slider = document.querySelectorAll('[data-image-modal-slider]');

  img_slider.forEach(el => {
    window.image_modal_slider = tns({
      "container": el,
      "autoHeight": false,
      "items": 1,
      "swipeAngle": false,
      "mouseDrag": true,
      "speed": 400,
      prevButton: '[data-image-modal-slider-btn="prev"]',
      nextButton: '[data-image-modal-slider-btn="next"]'

    });
  })

  var has_map = document.getElementById("map")

  if (has_map) {
    var mapKey = document.querySelector('meta[name="google-map-public-key"]').content

    importer.urls([
      '//maps.google.com/maps/api/js?key=' + mapKey
    ]).then(() => {
      window.loadGoogleMaps();
    });
  }

  var tag_properties = document.getElementById("tag_properties")

  if (tag_properties) {
    var properties = tag_properties.innerText
  }
  else {
    var properties = "{}"
  }

  ahoy.configure({
    useBeacon: true,
    visitsUrl: ESCOL_ANALYTICS,
    eventsUrl: ESCOL_ANALYTICS,
    startOnReady: true,
    trackVisits: false,
    cookies: true,
  });

  ahoy.trackView(JSON.parse(properties));


  register('pt-BR', localeFunc);

  window.timeago_render()

  var collapse_buttons = document.querySelectorAll('.mobile-menu__button');

  if (collapse_buttons.length > 0) {
    collapse_buttons.forEach(function (menu_box) {
      menu_box.addEventListener("click", openMenu);
    });
  }

  if (window.location.hash.substr(1) == 'filter-search__button') {
    event = { currentTarget: document.getElementById('filter-search__button') }
    openMenu(event)
  }


  var gallery_item = document.querySelectorAll('.js-photo-gallery__item');
  var modal_slider_photo = document.querySelectorAll('.js-photo-gallery__modal');

  gallery_item.forEach(el => {
    el.addEventListener('click', function(event) {
      modal_slider_photo[0].setAttribute("open", true);
      window.image_modal_slider.goTo(parseInt(el.dataset.img_order, 10));
      var next_button = modal_slider_photo[0].querySelectorAll('[data-image-modal-slider-btn="next"]');
        setTimeout(function() { next_button[0].focus(); }, 300);
    })
  });
}, false);

document.body.addEventListener('ajax:error', function(event) {
  window.notify('Algum erro aconteuceu =/, vamos dar uma olhada.', 'danger')
})
