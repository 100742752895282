import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {}
  static targets = ["templatePhotoItem", "appendPhotoItem", "schoolImageItems"];

  add_element(event) {
    this.appendPhotoItemTarget.appendChild(this.templatePhotoItemTarget.content.cloneNode(true));
    event.preventDefault();
  }

  preview_image(event) {
    this._readURL(event.target);
  }

  image_details_included(event) {
    window.notify("Informação inserida!", "success");
  }

  submit_image(event) {
    var form = event.target.closest("form");
    var event = new Event('submit', {bubbles: true});
    form.dispatchEvent(event);
    form.classList.add('loading-spiner');
  }

  image_included(event) {
    const [, , xhr] = event.detail
    var data =  xhr.response
    this.schoolImageItemsTarget.insertAdjacentHTML( 'beforeend', data );
    this.appendPhotoItemTarget.appendChild(this.templatePhotoItemTarget.content.cloneNode(true));
    event.target.remove()
  }

  remove_preview(event) {
    event.target.remove();
    this.appendPhotoItemTarget.appendChild(this.templatePhotoItemTarget.content.cloneNode(true));
  }

  load_input_field(event) {
    this.appendPhotoItemTarget.appendChild(this.templatePhotoItemTarget.content.cloneNode(true));
  }

  remove_element(event) {
    event.target.closest(".js-element-to-be-removed-after-destroy").remove();
  }

  _readURL(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function(e) {
        var logo_preview = input.closest(".js-component-file-input-with-sample");
        if (logo_preview) {

          var img_field = logo_preview.querySelector(".js-logo-preview");
          if (img_field) {
             img_field.remove();
          }
          var new_img = document.createElement("img");
          new_img.setAttribute("class", "w-32 js-logo-preview" );
          new_img.setAttribute("src", e.target.result );
          logo_preview.prepend(new_img);
        }
      }

      reader.readAsDataURL(input.files[0]);
    }
  }
}
